.floating-btn {
  background-color: white;
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: white;
}
.floating-icon {
  top: 0px;
  left: 0px;
  position: absolute;
  color: white;
  background-color: #25d366;
  border-radius: 50%;
  font-size: 51px;
  align-items: center;
}
