:root {
  --headingcolor: #252525;
  --carousel-p-fontsize: 22px;
  --carousel-p-fontcolor: #252525;
}
.Testimony {
  width: 100%;
  min-height: 100vh;
  max-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #f5f5f5;
  overflow: hidden;
}
.Testimony-container {
  width: 100%;
  height: 200px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: -100px;

}
.double-1 {
  width: 89px;
  height: 67px;
  top: 0;
  left: 6%;
  position: absolute;
}
.double-2 {
  width: 89px;
  height: 67px;
  position: absolute;
  right: 6%;
  bottom: 40%;
}
.carousel-text-container {
  width: 100%;
  height: 100%;
  padding: 5%;
  align-items: center;
  text-align: center;
  display: grid;
  justify-content: center;
}
.carousel-p {
  width: 821px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: var(--carousel-p-fontsize);
  line-height: 26px;
  color: var(--carousel-p-fontcolor);
}
.carousel-h3 {
  font-family: "Roboto", sans-serif;
}
.ant-carousel .slick-dots li button {
  background: #d9d9d9;
  border-radius: 2.5px;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button {
  background: linear-gradient(90.94deg, #93278f 0%, #0000ff 100%);
}
.ant-carousel .slick-dots li button:hover {
  background: linear-gradient(90.94deg, #93278f 0%, #0000ff 100%);
  scale: 1.1;
}
.test-background {
  visibility: hidden;
  width: 0;
  height: 0;
}
.mobile-first-ball {
  visibility: visible;
  position: absolute;
  width: 500px;
  height: 500px;
  margin-top: 50%;
  overflow: hidden;
}
.mobile-second-ball {
  visibility: visible;
  position: absolute;
  right: 0;
  width: 700px;
  height: 700px;
  margin-top: 30%;
  overflow: hidden;
}
@media (max-width: 1250px) {
  .double-1 {
    left: 5%;
  }
  .double-2 {
    right: 5%;
    bottom: 40%;
  }
}
@media (max-width: 1150px) {
  .double-1 {
    top: -10%;
    left: 3%;

    width: 79px;
    height: 67px;
  }
  .double-2 {
    right: 3%;
    bottom: 40%;
    width: 79px;
    height: 67px;
  }
  .mobile-first-ball {
    width: 500px;
    height: 500px;
    margin-top: 50%;
  }
  .mobile-second-ball {
    right: 0;
    width: 600px;
    height: 600px;
    margin-top: 30%;
  }
}
@media (max-width: 1000px) {
  .double-1 {
    top: -15%;
    left: 3%;
    width: 79px;
    height: 67px;
  }
  .double-2 {
    right: 3%;
    bottom: 40%;
    width: 79px;
    height: 67px;
  }
  .mobile-first-ball {
    width: 400px;
    height: 400px;
    margin-top: 50%;
  }
  .mobile-second-ball {
    right: -12%;
    width: 550px;
    height: 550px;
    margin-top: 30%;
  }
}
@media (max-width: 987px) {
  .carousel-p {
    width: 721px;
  }
  .double-1 {
    top: -10%;
    left: 3%;
  }
  .double-2 {
    right: 3%;
    bottom: 40%;
  }
}

@media (max-width: 855px) {
  .carousel-p {
    width: 521px;
  }
  .double-1 {
    width: 69px;
    height: 67px;
    top: -10%;
    left: 5%;
  }
  .double-2 {
    width: 69px;
    height: 67px;
    right: 5%;
  }
  .mobile-first-ball {
    width: 400px;
    height: 400px;
    margin-top: 25%;
  }
  .mobile-second-ball {
    right: -10%;
    width: 500px;
    height: 500px;
    margin-top: 70%;
  }
}
@media (max-width: 750px) {
  .mobile-first-ball {
    width: 300px;
    height: 300px;
    margin-top: 30%;
  }
  .mobile-second-ball {
    right: -10%;
    width: 400px;
    height: 400px;
    margin-top: 70%;
  }
}

@media (max-width: 650px) {
  :root {
    --carousel-p-fontsize: 18px;
  }
  .mobile-first-ball {
    left: 10%;
    width: 200px;
    height: 200px;
    margin-top: 30%;
  }
  .mobile-second-ball {
    right: -10%;
    width: 300px;
    height: 300px;
    margin-top: 90%;
  }
  .carousel-p {
    width: 421px;
  }
  .double-1 {
    width: 59px;
    height: 67px;
    top: -10%;
    left: 5%;
  }
  .double-2 {
    width: 59px;
    height: 67px;
    right: 5%;
  }
}

@media (max-width: 500px) {
  :root {
    --carousel-p-fontsize: 16px;
  }
  .mobile-first-ball {
    left: 10%;
    width: 200px;
    height: 200px;
    margin-top: 30%;
  }
  .mobile-second-ball {
    right: -10%;
    width: 300px;
    height: 300px;
    margin-top: 120%;
  }
  .carousel-p {
    width: 321px;
  }
  .double-1 {
    width: 49px;
    height: 67px;
    top: -15%;
    left: 5%;
  }
  .double-2 {
    width: 49px;
    height: 67px;
    right: 5%;
    bottom: 35%;
  }
}

@media (max-width: 400px) {
  :root {
    --carousel-p-fontsize: 14px;
  }
  .mobile-first-ball {
    left: 10%;
    width: 150px;
    height: 150px;
    margin-top: 30%;
  }
  .mobile-second-ball {
    right: -10%;
    width: 200px;
    height: 200px;
    margin-top: 150%;
  }
  .carousel-p {
    width: 300px;
  }
  .double-1 {
    width: 39px;
    height: 57px;
  }
  .double-2 {
    width: 39px;
    height: 57px;
  }
}

@media (max-width: 330px) {
  :root {
    --carousel-p-fontsize: 13px;
  }

  .carousel-p {
    width: 250px;
  }
  .double-1 {
    width: 29px;
    height: 47px;
  }
  .double-2 {
    width: 29px;
    height: 47px;
  }
}
@media (max-width: 302px) {
  :root {
    --carousel-p-fontsize: 12px;
  }
  .mobile-first-ball {
    left: 10%;
    width: 100px;
    height: 100px;
    margin-top: 35%;
  }
  .mobile-second-ball {
    right: -13%;
    width: 200px;
    height: 200px;
    margin-top: 170%;
  }
  .carousel-p {
    width: 200px;
  }
  .double-1 {
    width: 19px;
    height: 47px;
  }
  .double-2 {
    width: 19px;
    height: 47px;
  }
}
@media (min-width: 1265px) {
  .Testimony {
    overflow: visible;
  }
  .mobile-first-ball {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .mobile-second-ball {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  .backround-container {
    width: 1050px;
    position: relative;
  }
  .test-background {
    width: 100%;
    position: absolute;
    visibility: visible;
  }
  .test-first-ball {
    width: 600px;
    height: 600px;
    position: absolute;
    margin-top: -140px;
    margin-right: 0;
  }
  .test-second-ball {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-top: 80px;
    margin-left:-500px ;
  }
}
@media (min-width: 1362px) {
  .double-1 {
    left: 10%;
  }
  .double-2 {
    right: 10%;
  }
}
@media (min-width: 1500px) {
  .double-1 {
    left: 15%;
  }
  .double-2 {
    right: 15%;
  }
}
@media (min-width: 1700px) {
  .double-1 {
    left: 20%;
  }
  .double-2 {
    right: 20%;
  }
}
@media (min-width: 2000px) {
  .double-1 {
    left: 22%;
  }
  .double-2 {
    right: 22%;
  }
}
@media (min-width: 2200px) {
  .double-1 {
    left: 25%;
  }
  .double-2 {
    right: 26%;
  }
}
@media (min-width: 2400px) {
  .double-1 {
    left: 27%;
  }
  .double-2 {
    right: 28%;
  }
}
