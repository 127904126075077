:root {
  --fontcolor: #ffffff;
  --fontsize: 22px;
  --logofontsize: 40px;
}
.klogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Footer {
  width: 100%;
  min-height: 100vh;
  max-height: auto;
  background: linear-gradient(
    104.79deg,
    #93278f -18.7%,
    rgba(79, 21, 195, 0.911927) 44.16%,
    rgba(0, 0, 255, 0.81) 112.49%
  );
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer-container {
  width: 100%;
  min-height: 100vh;
  padding: 8% 2rem;
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.logo-address-container {
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.logo {
  float: left;
  cursor: pointer;
}
.Kreative-logo p {
  width: 404px;
  font-size: var(--fontsize);
  color: var(--fontcolor);
  font-family: "Roboto", sans-serif;
  font-family: 400;
  text-align: left;
}
.address p {
  font-size: var(--fontsize);
  color: var(--fontcolor);
  text-align: left;
  line-height: 2;
  font-family: "Roboto", sans-serif;
  font-family: 400;
}
.address p a {
  text-decoration: none;
  font-size: var(--fontsize);
  color: var(--fontcolor);
  text-align: left;
}
.social-nav-container {
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.social {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.social a {
  color: var(--fontcolor);
  font-size: var(--logofontsize);
  cursor: pointer;
}
.nav {
  float: right;
  width: 150px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.nav a {
  color: var(--fontcolor);
  font-size: var(--fontsize);
  cursor: pointer;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-family: 400;
}
.footer-end {
  color: var(--fontcolor);
  font-size: var(--fontsize);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  font-family: "Roboto", sans-serif;
  font-family: 400;
}

.mobile-footer-container {
  width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  visibility: hidden;
  gap: 70px;
  overflow: hidden;
}
.mobile-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.mobile-logo {
  width: 225px;
  height: 73.12px;
}
.mobile-footer-container P {
  width: 404px;
  font-size: var(--fontsize);
  color: var(--fontcolor);
  font-family: "Roboto", sans-serif;
  font-family: 400;
}
.mobile-nav {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.mobile-nav a {
  color: var(--fontcolor);
  font-size: var(--fontsize);
  cursor: pointer;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-family: 400;
}
.mobile-address p {
  font-size: var(--fontsize);
  color: var(--fontcolor);
  font-family: "Roboto", sans-serif;
  font-family: 400;
  line-height: 1.5;
}
.mobile-address p a {
  font-size: var(--fontsize);
  color: var(--fontcolor);
  text-decoration: none;
}
.mobile-social {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.mobile-social a {
  color: var(--fontcolor);
  font-size: var(--logofontsize);
  cursor: pointer;
}
@media (max-width: 900px) {
  .footer-container {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .footer-end {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .mobile-footer-container {
    visibility: visible;
    position: static;
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 5%;
  }
}
@media (max-width: 700px) {
  :root {
    --fontsize: 20px;
    --logofontsize: 38px;
  }
}
@media (max-width: 600px) {
  :root {
    --fontsize: 18px;
    --logofontsize: 36px;
  }
  .klogo {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 500px) {
  :root {
    --fontsize: 17px;
    --logofontsize: 35px;
  }
  .mobile-logo {
    width: 200px;
    height: 65px;
  }
  .mobile-footer-container P {
    width: 304px;
  }
}
@media (max-width: 450px) {
  :root {
    --fontsize: 17px;
    --logofontsize: 35px;
  }
  .mobile-logo {
    width: 200px;
    height: 65px;
  }
  .mobile-footer-container P {
    width: 304px;
  }
  .mobile-social {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
}
@media (max-width: 400px) {
  :root {
    --fontsize: 17px;
    --logofontsize: 35px;
  }
  .mobile-logo {
    width: 180px;
    height: 60px;
  }
  .mobile-footer-container P {
    width: 304px;
  }
  .mobile-social {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .klogo {
    width: 250px;
    height: 250px;
  }
}
@media (max-width: 325px) {
  :root {
    --fontsize: 16px;
    --logofontsize: 30px;
  }
  .mobile-logo {
    width: 160px;
    height: 50px;
  }
  .mobile-footer-container P {
    width: 280px;
  }
  .mobile-social {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .klogo {
    width: 250px;
    height: 250px;
  }
}
@media (max-width: 290px) {
  :root {
    --fontsize: 14px;
    --logofontsize: 28px;
  }
  .mobile-logo {
    width: 140px;
    height: 50px;
  }
  .mobile-footer-container P {
    width: 230px;
  }
  .mobile-social {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .klogo {
    width: 200px;
    height: 200px;
  }
}
@media (min-width: 1265px) {
  .footer-container {
    width: 1265px;
  }
}
/* @media (min-width: 1400px) {
  :root {
    --fontsize: 24px;
    --logofontsize: 42px;
  }
  .logo {
    width: 300px;
  }
}
@media (min-width: 1600px) {
  :root {
    --fontsize: 26px;
    --logofontsize: 44px;
  }
  .logo {
    width: 320px;
  }
  .nav {
    width: 200px;
  }
}
@media (min-width: 1800px) {
  :root {
    --fontsize: 28px;
    --logofontsize: 46px;
  }
  .logo {
    width: 340px;
  }
  .nav {
    width: 200px;
  }
}
@media (min-width: 2000px) {
  :root {
    --fontsize: 30px;
    --logofontsize: 48px;
  }
  .logo {
    width: 360px;
  }
  .nav {
    width: 200px;
  }
}
@media (min-width: 2200px) {
  :root {
    --fontsize: 32px;
    --logofontsize: 50px;
  }
  .logo {
    width: 380px;
  }
  .nav {
    width: 200px;
  }
}
@media (min-width: 2400px) {
  :root {
    --fontsize: 34px;
    --logofontsize: 52px;
  }
  .logo {
    width: 400px;
  }
  .nav {
    width: 200px;
  }
}
@media (min-width: 2500px) {
  :root {
    --fontsize: 36px;
    --logofontsize: 54px;
  }
  .logo {
    width: 420px;
  }
  .nav {
    width: 220px;
  }
} */
