:root {
  --homeh1fontcolor: #252525;
  --homeh1fontize: 60px;
}
.Home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;
}
.home-container {
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1000px;
  position: relative;
}
.home-background {
  width: 100%;
  height: 100%;
  position: absolute;
}

.first-background {
  margin-top: 10%;
  margin-left: -20%;
  width: 300px;
  height: 200px;
  position: absolute;
}
.first-ball {
  width: 500px;
  height: 500px;
  position: absolute;
  transition: all 0.5s ease;
}
.second-background {
  position: absolute;
  margin-left: 53%;
  margin-top: -10%;
}
.second-ball {
  width: 700px;
  height: 700px;
  position: absolute;
  transition: all 0.5s ease;
}
.home-h1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: var(--homeh1fontcolor);
  font-size: var(--homeh1fontize);
  font-weight: 700;
  text-align: center;
  display: flex;
  font-family: "Montserrat", sans-serif;
  transition: all ease-in-out;
  visibility: visible;
}

.home-h1-gradient {
  background: -webkit-linear-gradient(
    0deg,
    rgba(130, 35, 157, 1),
    rgba(35, 9, 229, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.home-h1-line {
  font-weight: 300;
  animation: blinker 0.7s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.downarrow-container {
  position: absolute;
  bottom: 100px;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin: 6px;
}
.downarrow {
  width: 60px;
  animation: move 1.1s infinite ease-in-out;
}
.double-arrow-hidden {
  visibility: hidden;
}
.mobile-h1 {
  width: 0%;
  height: 0%;
  visibility: hidden;
}
.mobile-type-h1 {
  width: 0%;
  height: 0%;
  visibility: hidden;
}
@keyframes move {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 50px;
  }
  100% {
    margin-top: 0;
  }
}
@media (max-width: 1000px) {
  .home-h1 {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .mobile-h1 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--homeh1fontcolor);
    font-size: var(--homeh1fontize);
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    visibility: visible;
    z-index: 1;
  }
  .mobile-type-h1 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--homeh1fontcolor);
    font-size: var(--homeh1fontize);
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    visibility: visible;
    z-index: 1;
  }
}
@media (max-width: 800px) {
  :root {
    --homeh1fontize: 60px;
  }
}
@media (max-width: 650px) {
  :root {
    --homeh1fontize: 50px;
  }
  .downarrow {
    width: 50px;
  }
}
@media (max-width: 545px) {
  :root {
    --homeh1fontize: 45px;
  }
}
@media (max-width: 500px) {
  :root {
    --homeh1fontize: 40px;
  }
  .downarrow {
    width: 50px;
  }
  .downarrow-container {
    bottom: 35px;
  }
}
@media (max-width: 400px) {
  :root {
    --homeh1fontize: 30px;
  }
  .downarrow {
    width: 35px;
  }
  .downarrow-container {
    bottom: 45px;
  }
}
@media (max-width: 300px) {
  :root {
    --homeh1fontize: 20px;
  }
  .downarrow {
    width: 30px;
  }
}
@media (max-width: 1265px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 25%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 500px;
    height: 500px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 15%;
    right: -10%;
    width: 700px;
    height: 700px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 1150px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 25%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 400px;
    height: 400px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 20%;
    right: -10%;
    width: 600px;
    height: 600px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 950px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 25%;
    top: 45%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 300px;
    height: 300px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 20%;
    right: -10%;
    width: 500px;
    height: 500px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 800px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 30%;
    top: 20%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 300px;
    height: 300px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 25%;
    right: -15%;
    width: 500px;
    height: 500px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 650px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 30%;
    top: 20%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 300px;
    height: 300px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 30%;
    right: -15%;
    width: 400px;
    height: 400px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 500px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 30%;
    top: 35%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 200px;
    height: 200px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 40%;
    right: -10%;
    width: 300px;
    height: 300px;
    transition: all 0.5s ease;
  }
}
@media (max-width: 350px) {
  .home-container {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .first-background {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 30%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .first-ball {
    width: 100px;
    height: 100px;
    transition: all 0.5s ease;
  }
  .second-background {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    overflow: hidden;
  }
  .second-ball {
    position: absolute;
    top: 50%;
    right: -10%;
    width: 200px;
    height: 200px;
    transition: all 0.5s ease;
  }
}
