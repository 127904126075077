@font-face {
  font-family: "Toxigenesis";
  src: local("Toxigenesis"),
   url("./assets/toxigenesis bd.ttf");
  font-weight: bold;
  }
  @font-face {
    font-family: "quicksilver";
    src: local("quicksilver"),
     url("./assets/Quicksilver.ttf");
    font-weight: bold;
    }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Toxigenesis','quicksilver',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
