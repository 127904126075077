:root {
  --serviceheadingcolor: #252525;
  --serviceheadingfontsize: 110px;
  --servicepcolor: #717171;
  --servicepfontsize: 22px;
  --marqueefontsize: 80px;
  --marqueecolor: #ffffff;
}
.Ourservices {
  width: 100%;
  height: auto;
  padding-top: 5%;
  background-color: #f5f5f5;
}
.ourservice-mobile-content {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.ourservice-content {
  width: 100%;
  padding: 8% 2rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.marquee-text {
  width: 100%;
 
  background: linear-gradient(
    90.64deg,
    #252525 0%,
    #333333 37.5%,
    #646363 45.83%,
    #5a5a5a 50.52%,
    #000000 100%
  );
  color: var(--marqueecolor);
  font-size: var(--marqueefontsize);
  font-weight: 400;
  text-transform: capitalize;
  font-family: 'quicksilver';
  letter-spacing: 10px;
}
.marquee-text h1{
  margin-top: -15px;
}
.ourservice-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.ourservice-container h1 {
  font-size: var(--serviceheadingfontsize);
  color: var(--serviceheadingcolor);
  font-weight: 700;
  line-height: 144px;
  font-family: "Toxigenesis";
}
.ourservice-container p {
  width: 806px;
  margin-top: 4%;
  font-size: var(--servicepfontsize);
  color: var(--servicepcolor);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 26px;
}
.ourservice-text-1 {
  text-align: left;
  position: relative;
}
.ourservice-text-2 {
  text-align: left;
}
.ourservice-text-3 {
  text-align: left;
}
.image-one,
.image-two,
.image-three {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
.image-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: -70px;
  margin-left: 60px;
}
.image-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.secondData {
  opacity: 0;
}
.thirdData {
  opacity: 0;
}
.os-mobile-first-ball {
  position: absolute;
  margin-top: -50%;
  left: 0;
}
.os-mobile-second-ball {
  position: absolute;
  margin-top: -50%;
  right: 0;
}
.os-mobile-third-ball {
  position: absolute;
  margin-top: -50%;
  left: 0;
}

@media (max-width: 1265px) {
  .ourservice-content {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
  }
  .ourservice-mobile-content {
    margin-top: 60px;
    visibility: visible;
    width: 100%;
    height: 100%;
    padding: 8% 8%;
    position: static;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }
  .ourservice-mobile-content h1 {
    font-size: var(--serviceheadingfontsize);
    color: var(--serviceheadingcolor);
    font-weight: 700;
    line-height: 144px;
    font-family: "Toxigenesis";
  }
  .ourservice-mobile-content p {
    margin-top: 4%;
    font-size: var(--servicepfontsize);
    color: var(--servicepcolor);
    font-weight: 400;
    line-height: 26px;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }
  .first-mobile-text,
  .second-mobile-text,
  .third-mobile-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }
}
@media (max-width: 900px) {
  :root {
    --serviceheadingfontsize: 100px;
    --servicepfontsize: 22px;
    --marqueefontsize: 40px;
  }
  .os-mobile-first-ball,
  .os-mobile-second-ball,
  .os-mobile-third-ball {
    width: 500px;
    margin-top: -70%;
  }
  .mobile-one,
  .mobile-two,
  .mobile-three {
    width: 111.8px;
    height: 226.13px;
  }
  .ourservice-mobile-content h1 {
    line-height: 124px;
  }
  .marquee-text h1{
    margin-top: -8px;
  }
}
@media (max-width: 800px) {
  :root {
    --serviceheadingfontsize: 90px;
    --servicepfontsize: 20px;
    --marqueefontsize: 35px;
  }
  .mobile-one,
  .mobile-two,
  .mobile-three {
    width: 111.8px;
    height: 226.13px;
  }
  .ourservice-mobile-content h1 {
    line-height: 124px;
  }
  .marquee-text {
    width: 100%;
    height: 90px;
  }
}
@media (max-width: 700px) {
  :root {
    --serviceheadingfontsize: 80px;
    --servicepfontsize: 20px;
    --marqueefontsize: 35px;
  }
  .ourservice-mobile-content h1 {
    line-height: 90px;
  }
}
@media (max-width: 600px) {
  :root {
    --serviceheadingfontsize: 70px;
    --servicepfontsize: 18px;
    --marqueefontsize: 37px;
  }
  .marquee-text h1{
    margin-top: -6px;
  }
  .os-mobile-first-ball,
  .os-mobile-second-ball,
  .os-mobile-third-ball {
    width: 400px;
    margin-top: -90%;
  }
  .ourservice-mobile-content h1 {
    line-height: 80px;
  }
  .first-mobile-text,
  .second-mobile-text,
  .third-mobile-text {
    gap: 40px;
  }
}
@media (max-width: 530px) {
  :root {
    --serviceheadingfontsize: 60px;
    --servicepfontsize: 16px;
    --marqueefontsize: 32px;
  }
  .os-mobile-first-ball,
  .os-mobile-second-ball,
  .os-mobile-third-ball {
    width: 300px;
    margin-top: -100%;
  }
  .ourservice-mobile-content h1 {
    line-height: 80px;
  }
  .first-mobile-text,
  .second-mobile-text,
  .third-mobile-text {
    gap: 40px;
  }
  .marquee-text {
    width: 100%;
    height: 80px;
  }
}
@media (max-width: 450px) {
  :root {
    --serviceheadingfontsize: 50px;
    --servicepfontsize: 14px;
    --marqueefontsize: 28px;
  }
  .marquee-text h1{
    margin-top: -5px;
  }
  .os-mobile-third-ball {
    margin-top: -120%;
  }
  .ourservice-mobile-content h1 {
    line-height: 70px;
  }
  .first-mobile-text,
  .second-mobile-text,
  .third-mobile-text {
    gap: 40px;
  }
  .marquee-text {
    width: 100%;
    height: 70px;
  }
  .mobile-one,
  .mobile-two,
  .mobile-three {
    width: 90;
    height: 90px;
  }
}
@media (max-width: 375px) {
  :root {
    --serviceheadingfontsize: 40px;
    --servicepfontsize: 14px;
    --marqueefontsize: 25px;
  }
  .ourservice-mobile-content h1 {
    line-height: 50px;
  }
  .first-mobile-text,
  .second-mobile-text,
  .third-mobile-text {
    gap: 30px;
  }
  .marquee-text {
    width: 100%;
    height: 70px;
  }
  .mobile-one,
  .mobile-two,
  .mobile-three {
    width: 90;
    height: 90px;
  }
  .ourservice-mobile-content {
    gap: 80px;
  }
}
@media (max-width: 350px) {
  .os-mobile-first-ball,
  .os-mobile-second-ball {
    width: 200px;
    margin-top: -120%;
  }
  .os-mobile-third-ball {
    width: 200px;
    margin-top: -140%;
  }
}
@media (max-width: 300px) {
  :root {
    --serviceheadingfontsize: 30px;
    --servicepfontsize: 14px;
    --marqueefontsize: 15px;
  }
  .ourservice-mobile-content h1 {
    line-height: 50px;
  }
  .first-mobile-text,
  .second-mobile-text,
  .third-mobile-text {
    gap: 20px;
  }
  .marquee-text {
    width: 100%;
    height: 50px;
  }
  .mobile-one,
  .mobile-two,
  .mobile-three {
    width: 90;
    height: 90px;
  }
  .ourservice-mobile-content {
    gap: 50px;
  }
}
@media (min-width: 1265px) {
  .ourservice-container {
    width: 1050px;
  }

  .os-first-ball {
    width: 800px;
    position: absolute;
    margin-top: -340px;
    margin-left: -160px;

  }
 
  .os-second-ball {
    width: 800px;
    position: absolute;
    margin-top: -340px;
    margin-left: 200px;

  }

  .os-third-ball {
    width: 800px;
    position: absolute;
    margin-top: -340px;
    margin-left: -160px;

  }
}
