
:root {
  --navbarcolor: transparent;
  --navbartextcolor: #252525;
  --navbarfontsize: 14px;
  --navbarhovercolor: rgba(103, 28, 179, 1);
  --navbarbuttontextcolor: #ffffff;
  --navbarbuttontextsize: 16px;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 0 2rem;
  background-color: var(--navbarcolor);
  color: var(--navbartextcolor);
  position: fixed;
  z-index: 999;
}
.nav-btn {
  position: absolute;
  background: transparent;
  border: none;
  color: #252525;
  font-size: 22px;
  visibility: hidden;
}
.active {
  background-color: #f5f5f5;
  height: 75px;
  transition: all 0.3s ease-out 0s;
  z-index: 999;
}
.navlogo {
  cursor: pointer;
  width: 125px;
  height: 41px;
}
nav {
  height: 100%;
  width: 550px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
nav a {
  color: var(--navbartextcolor);
  text-decoration: none;
  font-size: var(--navbarfontsize);
  font-style: normal;
  font-weight: 500;
  position: relative;
  cursor: pointer;
 font-family: 'Roboto', sans-serif;
}

nav a:before {
  content: "";
  position: absolute;
  width: 0%;
  height: 4px;
  border-radius: 5px;
  bottom: -5px;
  left: 0;
  background-color: var(--navbarhovercolor);
  visibility: hidden;
  transform: scaleX(1);
  transition: all 0.3s ease-in-out 0s;
}

nav a .nav-active:before {
  visibility: visible;
  transform: scale(1);
  width: 100%;
}
.hire-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 9px;
  width: 120px;
  height: 40px;
  background: linear-gradient(98.1deg, #93278f -28.59%, #0000ff 147.51%);
  border-radius: 10px;
  border: none;
  color: var(--navbarbuttontextcolor);
  font-size: var(--navbarbuttontextsize);
  cursor: pointer;
  transition: 1s;
}
.hire-button:hover {
  scale: 1.1;
}
@media (max-width: 1000px) {
  .nav-btn {
    visibility: visible;
    position: relative;
  }
  .header nav {
    position: fixed;
    top: 0;
    left: -300vh;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    background-color: #f5f5f5;
    transition: 1s;
    z-index: 9999999;
  }
  nav a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header .responsive_nav {
    transform: translateX(300vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1.9rem;
    left: 2rem;
    color: black;
  }
}
@media (max-width: 600px) {
  :root {
    --navbarfontsize: 16px;
    --navbarbuttontextsize: 16px;
  }
  .hire-button {
    width: 100px;
    height: 40px;
  }
  .navlogo {
    width: 120px;
    height: 41px;
  }
  .nav-btn {
    font-size: 21px;
  }
}
@media (max-width: 450px) {
  :root {
    --navbarfontsize: 16px;
    --navbarbuttontextsize: 14px;
  }
  .hire-button {
    width: 85px;
    height: 35px;
  }
  .navlogo {
    width: 110px;
    height: 41px;
  }
  .nav-btn {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  :root {
    --navbarfontsize: 16px;
    --navbarbuttontextsize: 12px;
  }
  .hire-button {
    width: 75px;
    height: 30px;
  }
  .navlogo {
    width: 90px;
    height: 41px;
  }
  .nav-btn {
    font-size: 17px;
  }
}
@media (max-width: 280px) {
  :root {
    --navbarfontsize: 16px;
    --navbarbuttontextsize: 11px;
  }
  .hire-button {
    width: 65px;
    height: 25px;
  }
  .navlogo {
    width: 80px;
    height: 41px;
  }
  .nav-btn {
    font-size: 15px;
  }
}
