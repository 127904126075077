:root {
  --aboutheadingfontsize: 120px;
  --aboutheadingfontcolor: #252525;
  --pfontcolor: #717171;
  --pfontsize: 22px;
  --about-2-h1fontsize: 50px;
  --about-2-box-h3-fontsize: 22px;
  --about-2-box-h3-fontcolor: #717171;
  --about-2-box-h1-fontsize: 45px;
  --about-2-box-h1-fontcolor: #252525;
  --about-2-box-p-fontsize: 18px;
  --about-2-box-p-fontcolor: #717171;
}
.About {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}
.about-container {
  width: 100%;
  height: 100%;
  padding: 8% 8%;
}
@media (min-width: 1265px) {
  .about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .about-content {
    width: 1050px;
  }
  .about-heading {
    width: 1050px;
  }
}
.about-content {
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  text-align: left;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.about-heading {
  color: var(--aboutheadingfontcolor);
  font-family: "Toxigenesis";
  font-style: normal;
  font-weight: 700;
  font-size: var(--aboutheadingfontsize);
  line-height: 144px;
  text-align: left;
}
.about-content p {
  width: 585px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: var(--pfontsize);
  line-height: 26px;
  color: var(--pfontcolor);
}
.about-k-logo-container {
  margin-bottom: 20%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.about-k-logo {
  width: 192px;
  height: 192px;
}

.responsive-logo {
  visibility: hidden;
  position: static;
}
@media (max-width: 1000px) {
  .about-heading {
    text-align: center;
  }
  .about-content {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .about-content p {
    width: 100%;
    text-align: left;
  }
  .about-k-logo-container {
    margin-bottom: 10%;
  }
}
@media (max-width: 850px) {
  :root {
    --aboutheadingfontsize: 100px;
    --pfontsize: 20px;
  }
  .about-k-logo {
    margin-top: 0px;
    width: 180px;
    height: 180px;
  }
}
@media (max-width: 600px) {
  :root {
    --aboutheadingfontsize: 80px;
    --pfontsize: 18px;
  }
  .about-k-logo {
    width: 160px;
    height: 160px;
  }
}
@media (max-width: 500px) {
  :root {
    --aboutheadingfontsize: 60px;
    --pfontsize: 16px;
  }
  .about-k-logo {
    width: 130px;
    height: 120px;
  }
  .about-heading {
    line-height: 100px;
  }
}
@media (max-width: 400px) {
  :root {
    --aboutheadingfontsize: 50px;
    --pfontsize: 15px;
  }
  .about-k-logo {
    margin-top: 10px;
    width: 120px;
    height: 120px;
  }
  .about-heading {
    line-height: 80px;
  }
}
@media (max-width: 300px) {
  :root {
    --aboutheadingfontsize: 40px;
    --pfontsize: 14px;
  }
  .about-k-logo {
    width: 80px;
    height: 80px;
  }
  .about-heading {
    line-height: 60px;
  }
}

.about-2 {
  margin-top: 10%;
}
.about-2-h1 {
  font-size: var(--about-2-h1fontsize);
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.about-2-h1-gradient {
  background: -webkit-linear-gradient(
    0deg,
    rgba(130, 35, 157, 1),
    rgba(35, 9, 229, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (min-width: 1265px) {
  .about-2-box-container {
    width: 1100px;
  }
}
.about-2-box-container {
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.about-2-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  height: 250px;
}
.about-2-box h3 {
  font-size: var(--about-2-box-h3-fontsize);
  color: var(--about-2-box-h3-fontcolor);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
.about-2-box h1 {
  font-size: var(--about-2-box-h1-fontsize);
  color: var(--about-2-box-h1-fontcolor);
  font-weight: 700;
  font-family: "Toxigenesis";
}
.count-h1 {
  font-weight: 700;
  font-family: "Toxigenesis";
}
.about-2-box p {
  font-size: var(--about-2-box-p-fontsize);
  color: var(--about-2-box-p-fontcolor);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
@media (max-width: 650px) {
  :root {
    --about-2-h1fontsize: 40px;
    --about-2-box-h3-fontsize: 20px;
    --about-2-box-h1-fontsize: 40px;
    --about-2-box-p-fontsize: 17px;
  }
}
@media (max-width: 500px) {
  :root {
    --about-2-h1fontsize: 35px;
    --about-2-box-h3-fontsize: 18px;
    --about-2-box-h1-fontsize: 38px;
    --about-2-box-p-fontsize: 16px;
  }
  .about-2-box {
    height: 200px;
  }
}
@media (max-width: 410px) {
  :root {
    --about-2-h1fontsize: 30px;
    --about-2-box-h3-fontsize: 17px;
    --about-2-box-h1-fontsize: 36px;
    --about-2-box-p-fontsize: 15px;
  }
  .about-2-box {
    height: 200px;
  }
}
@media (max-width: 352px) {
  :root {
    --about-2-h1fontsize: 28px;
    --about-2-box-h3-fontsize: 16px;
    --about-2-box-h1-fontsize: 33px;
    --about-2-box-p-fontsize: 14px;
  }
}
@media (max-width: 330px) {
  :root {
    --about-2-h1fontsize: 25px;
    --about-2-box-h3-fontsize: 16px;
    --about-2-box-h1-fontsize: 33px;
    --about-2-box-p-fontsize: 14px;
  }
}
@media (max-width: 292px) {
  :root {
    --about-2-h1fontsize: 22px;
    --about-2-box-h3-fontsize: 14px;
    --about-2-box-h1-fontsize: 30px;
    --about-2-box-p-fontsize: 13px;
  }
  .about-2-box {
    height: 150px;
  }
}
