@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	
}
.App {
  text-align: center;
}

