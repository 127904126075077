:root {
  --contactheadingfontsize: 35px;
  --contactheadingcolor: #ffffff;
  --submitbtnfontsize: 53px;
  --submitbtncolor: #ffffff;
  --inputboxfontsize: 16px;
}
.Contact {
  width: 100%;
  min-height: 100vh;
  max-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    104.85deg,
    #252525 0%,
    #212121 42.19%,
    #373737 51.04%,
    #000000 100%
  );
  padding: 8% 6%;
}
.contact-heading {
  width: 100%;
  text-transform: uppercase;
  font-size: var(--contactheadingfontsize);
  color: var(--contactheadingcolor);
  font-weight: 700;
  font-family: "toxigenesis";
}
.contact-inputs {
  margin-top: 6%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.text-input {
  width: 48%;
  height: 59px;
  padding-left: 20px;
  font-size: var(--inputboxfontsize);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.text-email {
  width: 48%;
  height: 59px;
  padding-left: 20px;
  font-size: var(--inputboxfontsize);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.input-textarea {
  width: 98%;
  height: 214px;
  padding: 20px;
  font-size: var(--inputboxfontsize);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.submit-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.submit-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-family: "Toxigenesis";
  font-style: normal;
  font-weight: 700;
  font-size: var(--submitbtnfontsize);
  color: var(--submitbtncolor);
  line-height: 64px;
  position: relative;
}
.submit-button:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 53%;
  height: 6px;
  background-color: var(--submitbtncolor);
  transition: 0.5s ease;
}
.submit-button:hover:before {
  width: 100%;
}
@media (max-width: 900px) {
  :root {
    --contactheadingfontsize: 33px;
    --submitbtnfontsize: 50px;
    --inputboxfontsize: 16px;
  }
  .submit-btn-container {
    justify-content: center;
  }
}
@media (max-width: 750px) {
  :root {
    --contactheadingfontsize: 30px;
    --submitbtnfontsize: 47px;
    --inputboxfontsize: 16px;
  }
}
@media (max-width: 680px) {
  :root {
    --contactheadingfontsize: 28px;
    --submitbtnfontsize: 45px;
    --inputboxfontsize: 15px;
  }
}
@media (max-width: 650px) {
  :root {
    --contactheadingfontsize: 26px;
    --submitbtnfontsize: 43px;
    --inputboxfontsize: 15px;
  }
  .text-input {
    width: 100%;
    height: 59px;
  }
  .text-email {
    width: 100%;
    height: 59px;
  }
  .input-textarea {
    width: 100%;
    height: 214px;
  }
}
@media (max-width: 550px) {
  :root {
    --contactheadingfontsize: 22px;
    --submitbtnfontsize: 38px;
    --inputboxfontsize: 15px;
  }
  .text-input {
    width: 100%;
  }
  .text-email {
    width: 100%;
  }
  .input-textarea {
    width: 100%;
  }
}
@media (max-width: 500px) {
  :root {
    --contactheadingfontsize: 20px;
    --submitbtnfontsize: 36px;
    --inputboxfontsize: 14px;
  }
  .text-input {
    width: 100%;
  }
  .text-email {
    width: 100%;
  }
  .input-textarea {
    width: 100%;
  }
}
@media (max-width: 455px) {
  :root {
    --contactheadingfontsize: 16px;
    --submitbtnfontsize: 30px;
    --inputboxfontsize: 14px;
  }
  .text-input {
    width: 100%;
  }
  .text-email {
    width: 100%;
  }
  .input-textarea {
    width: 100%;
  }
}
@media (max-width: 361px) {
  :root {
    --contactheadingfontsize: 14px;
    --submitbtnfontsize: 28px;
    --inputboxfontsize: 14px;
  }
  .text-input {
    width: 100%;
    height: 50px;
  }
  .text-email {
    width: 100%;
    height: 50px;
  }
  .input-textarea {
    width: 100%;
    height: 180px;
  }
}
@media (max-width: 317px) {
  :root {
    --contactheadingfontsize: 12px;
    --submitbtnfontsize: 22px;
    --inputboxfontsize: 12px;
  }
  .text-input {
    width: 100%;
    height: 40px;
  }
  .text-email {
    width: 100%;
    height: 40px;
  }
  .input-textarea {
    width: 100%;
    height: 150px;
  }
  .submit-button:before {
    height: 3px;
  }
}
